/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const ProShape: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='88' height='88' viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_10174_749)'>
                <path opacity='0.8' d='M82.8945 35.6296C82.8945 31.7892 82.8945 29.8691 82.3585 28.1242C81.9225 26.705 81.2304 25.3784 80.3165 24.2104C79.1929 22.7744 77.6205 21.6809 74.4757 19.4938L54.8243 5.82709C50.7269 2.97753 48.6782 1.55276 46.4451 1.02708C44.6337 0.600672 42.7486 0.600344 40.937 1.02612C38.7037 1.55102 36.6545 2.97508 32.5562 5.82321L12.8839 19.4943C9.73708 21.6811 8.16367 22.7746 7.03937 24.2108C6.1249 25.379 5.43223 26.706 4.99593 28.1257C4.45953 29.8711 4.45953 31.792 4.45953 35.634V58.0727C4.45953 59.9937 4.45953 60.9542 4.72773 61.8269C4.94588 62.5367 5.29222 63.2002 5.74945 63.7843C6.3116 64.5024 7.09831 65.0491 8.67172 66.1426L32.5562 82.7409C36.6545 85.589 38.7037 87.0131 40.937 87.5379C42.7486 87.9637 44.6337 87.9634 46.4451 87.537C48.6782 87.0113 50.7269 85.5865 54.8243 82.737L74.4757 69.0703C77.6205 66.8832 79.1929 65.7896 80.3165 64.3537C81.2304 63.1857 81.9225 61.8591 82.3585 60.4398C82.8945 58.695 82.8945 56.7748 82.8945 52.9345V35.6296Z' fill='#482C7D' />
                <path opacity='0.7' d='M75.1478 37.3599C75.1478 34.2876 75.1478 32.7515 74.7243 31.3556C74.3798 30.2203 73.8329 29.1589 73.1108 28.2246C72.2231 27.0758 70.9807 26.201 68.4959 24.4513L52.9689 13.5179C49.7314 11.2383 48.1127 10.0985 46.3483 9.67791C44.917 9.33679 43.4276 9.33653 41.9962 9.67715C40.2316 10.0971 38.6125 11.2363 35.3743 13.5148L19.8308 24.4517C17.3444 26.2012 16.1012 27.0759 15.2129 28.2249C14.4903 29.1594 13.943 30.221 13.5983 31.3568C13.1745 32.7531 13.1745 34.2899 13.1745 37.3634V55.3144C13.1745 56.8512 13.1745 57.6196 13.3864 58.3178C13.5588 58.8856 13.8324 59.4164 14.1937 59.8837C14.6379 60.4582 15.2595 60.8956 16.5026 61.7703L35.3743 75.0489C38.6125 77.3274 40.2316 78.4667 41.9962 78.8866C43.4276 79.2272 44.917 79.227 46.3483 78.8858C48.1127 78.4653 49.7314 77.3255 52.9689 75.0458L68.4959 64.1125C70.9807 62.3628 72.2231 61.488 73.1108 60.3392C73.8329 59.4048 74.3798 58.3435 74.7243 57.2081C75.1478 55.8123 75.1478 54.2761 75.1478 51.2038V37.3599Z' fill='#E1D9F2' />
                <mask id='mask0_10174_749' style={{
        maskType: "alpha"
      }} maskUnits='userSpaceOnUse' x='13' y='9' width='63' height='71'>
                    <path d='M75.1479 37.3599C75.1479 34.2876 75.1479 32.7515 74.7244 31.3556C74.3799 30.2203 73.833 29.1589 73.1109 28.2246C72.2232 27.0758 70.9808 26.201 68.496 24.4513L52.9689 13.5179C49.7315 11.2383 48.1128 10.0985 46.3483 9.67791C44.9171 9.33679 43.4276 9.33653 41.9963 9.67715C40.2317 10.0971 38.6126 11.2363 35.3744 13.5148L19.8309 24.4517C17.3445 26.2012 16.1013 27.0759 15.213 28.2249C14.4904 29.1594 13.9431 30.221 13.5984 31.3568C13.1746 32.7531 13.1746 34.2899 13.1746 37.3634V55.3144C13.1746 56.8512 13.1746 57.6196 13.3865 58.3178C13.5588 58.8856 13.8325 59.4164 14.1938 59.8837C14.6379 60.4582 15.2595 60.8956 16.5027 61.7703L35.3744 75.0489C38.6126 77.3274 40.2317 78.4667 41.9963 78.8866C43.4276 79.2272 44.9171 79.227 46.3483 78.8858C48.1128 78.4653 49.7315 77.3255 52.9689 75.0458L68.496 64.1125C70.9808 62.3628 72.2232 61.488 73.1109 60.3392C73.833 59.4048 74.3799 58.3435 74.7244 57.2081C75.1479 55.8123 75.1479 54.2761 75.1479 51.2038V37.3599Z' fill='#22BEC3' />
                </mask>
                <g mask='url(#mask0_10174_749)'>
                    <path fillRule='evenodd' clipRule='evenodd' d='M75.1479 37.3599V37.3599V51.2038V51.2039C75.1479 54.2761 75.1479 55.8123 74.7244 57.2081C74.3799 58.3435 73.833 59.4048 73.1109 60.3392C72.2232 61.488 70.9808 62.3628 68.496 64.1124L68.496 64.1124L52.9689 75.0458C49.7315 77.3255 48.1128 78.4653 46.3483 78.8858C45.6292 79.0572 44.8953 79.1426 44.1616 79.1419C39.3199 67.5219 41.2566 51.0603 44.1616 42.3453C33.5956 54.6723 20.6277 57.3917 13.2661 57.7836C13.1746 57.1985 13.1746 56.4759 13.1746 55.3144V37.3634C13.1746 34.2899 13.1746 32.7531 13.5984 31.3568C13.9431 30.221 14.4904 29.1594 15.213 28.2249C16.1013 27.0759 17.3445 26.2012 19.8309 24.4517L35.3744 13.5148C38.6126 11.2363 40.2317 10.0971 41.9963 9.67715C43.4276 9.33653 44.9171 9.33679 46.3483 9.67791C48.1128 10.0985 49.7315 11.2383 52.9689 13.5179L68.496 24.4513C70.9808 26.201 72.2232 27.0758 73.1109 28.2245C73.833 29.1589 74.3799 30.2203 74.7244 31.3556C75.1479 32.7515 75.1479 34.2876 75.1479 37.3599Z' fill='#8966CC' />
                    <g filter='url(#filter0_i_10174_749)'>
                        <path d='M23.0354 59.3682L27.5524 55.0525L39.0897 63.4348L36.381 69.0643C35.7916 70.2892 34.2368 70.6813 33.137 69.8823L23.2599 62.7062C22.1602 61.9072 22.0526 60.3073 23.0354 59.3682Z' fill='#8966CC' />
                    </g>
                    <g filter='url(#filter1_i_10174_749)'>
                        <path d='M25.7874 51.0805L17.7167 45.2168C17.302 44.9155 17.1855 44.3686 17.4695 43.996C18.598 42.5153 21.4777 39.027 24.4277 37.7814C32.4651 34.3875 36.0325 36.9794 36.0325 36.9794L25.7874 51.0805Z' fill='#E1D9F2' />
                    </g>
                    <g filter='url(#filter2_i_10174_749)'>
                        <path d='M43.4147 63.8874L51.4854 69.7511C51.9001 70.0524 52.4563 69.9942 52.7228 69.6091C53.7823 68.0782 56.2101 64.2614 56.4832 61.071C57.2272 52.3781 53.6598 49.7863 53.6598 49.7863L43.4147 63.8874Z' fill='#E1D9F2' />
                    </g>
                    <g filter='url(#filter3_di_10174_749)'>
                        <path d='M59.0474 22.4307C36.3059 23.9268 27.4404 40.1101 21.8705 51.8757C21.5773 52.4949 21.4307 52.8046 21.5085 53.1091C21.5863 53.4137 21.8652 53.6163 22.423 54.0215L41.3528 67.7749C41.8348 68.125 42.0758 68.3001 42.3658 68.296C42.6557 68.2919 42.8789 68.1199 43.3252 67.7759C47.9715 64.1947 67.3468 47.5584 60.7723 23.6559C60.602 23.0366 60.5168 22.727 60.264 22.5472C60.0112 22.3673 59.6899 22.3884 59.0474 22.4307Z' fill='white' />
                    </g>
                    <circle cx='48.689' cy='38.0927' r='6.53625' transform='rotate(36 48.689 38.0927)' fill='#E1D9F2' />
                    <g filter='url(#filter4_i_10174_749)'>
                        <circle cx='48.6878' cy='38.0924' r='4.3575' transform='rotate(36 48.6878 38.0924)' fill='#8966CC' />
                    </g>
                </g>
            </g>
            <defs>
                <filter id='filter0_i_10174_749' x='22.3618' y='55.0527' width='16.7279' height='23.9611' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='8.715' />
                    <feGaussianBlur stdDeviation='4.3575' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.282353 0 0 0 0 0.172549 0 0 0 0 0.490196 0 0 0 0.3 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10174_749' />
                </filter>
                <filter id='filter1_i_10174_749' x='17.3135' y='33.8545' width='18.719' height='17.2256' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='-2.17875' />
                    <feGaussianBlur stdDeviation='4.3575' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.654118 0 0 0 0 0.550588 0 0 0 0 0.849412 0 0 0 0.4 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10174_749' />
                </filter>
                <filter id='filter2_i_10174_749' x='43.4147' y='47.6084' width='13.1705' height='22.335' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='-2.17875' />
                    <feGaussianBlur stdDeviation='4.3575' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.654118 0 0 0 0 0.550588 0 0 0 0 0.849412 0 0 0 0.4 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10174_749' />
                </filter>
                <filter id='filter3_di_10174_749' x='14.9516' y='20.2236' width='53.7045' height='58.967' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='4.3575' />
                    <feGaussianBlur stdDeviation='3.26813' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.192157 0 0 0 0 0.141176 0 0 0 0 0.376471 0 0 0 0.2 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10174_749' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10174_749' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='-2.17875' />
                    <feGaussianBlur stdDeviation='1.08938' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.654118 0 0 0 0 0.550588 0 0 0 0 0.849412 0 0 0 0.4 0' />
                    <feBlend mode='normal' in2='shape' result='effect2_innerShadow_10174_749' />
                </filter>
                <filter id='filter4_i_10174_749' x='44.33' y='31.5556' width='8.71564' height='10.8955' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='-2.17875' />
                    <feGaussianBlur stdDeviation='2.17875' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.423529 0 0 0 0 0.25098 0 0 0 0 0.74902 0 0 0 1 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_10174_749' />
                </filter>
                <clipPath id='clip0_10174_749'>
                    <rect width='87.15' height='87.15' fill='white' transform='translate(0.102051 0.707031)' />
                </clipPath>
            </defs>
        </svg>;
};

export default ProShape;